import { FunctionalComponent, h } from "preact";
import * as style from "./style.less";

const Footer: FunctionalComponent = () => {
  return (
    <footer class={style.footer}>
      <span class={style.copyright}></span>
      <span class={style.links}>友情链接： <a href="https://meta.zaker.cn/solution/partybuilding/" title="元宇宙党建" target="_blank">元宇宙党建</a>  <a href="https://meta.zaker.cn/solution/culturaltourism/" title="元宇宙文旅" target="_blank">元宇宙文旅</a> <a href="https://rongmeiti.myzaker.com/" title="融媒体解决方案" target="_blank">融媒体解决方案</a></span>
      <span class={style.onRecordBox}>
        <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备20059828号</a>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009154"><img style="margin-right: 4px" src="http://www.myzaker.com/images/new/bottom/ga.png" />粤公网安备 44010602009154号</a>
      </span>

      <p class={style.fixedTips}>
        <span class={style.tipsContent}>
          电脑浏览器打开 <strong>yiqijian.com</strong> 免费使用
        </span>
      </p>
    </footer>
  );
};

export default Footer;
